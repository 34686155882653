import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import NewRelic from '../Tracking/NewRelic';
import ParselyLdJson from '../Tracking/ParselyLdJson';

const SEO = ({
    title = {},
    description = '',
    keywords = '',
    image = '',
    type = '',
    publishDate = '',
    articleSection = '',
    urlPath = ''
}) => {
    return (
        <Helmet
            title={title ? title.text : 'Garfield'}
            htmlAttributes={{
                lang: 'en',
            }}
        >
            <link
                rel="preload"
                as="font"
                href="https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3"
                crossorigin="anonymous"
            />
            <link
                rel="preload"
                as="font"
                href="https://use.typekit.net/af/8738d8/00000000000000007735e611/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3"
                crossorigin="anonymous"
            />
            <link
                rel="preload"
                as="font"
                href="https://use.typekit.net/af/df315a/00000000000000007735a122/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3"
                crossorigin="anonymous"
            />

            <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={process.env.GATSBY_ONETRUST_DATA_DOMAIN_SCRIPT}
                    defer
                ></script>
            {/* OneTrust */}
            <script
                type="text/javascript"
                src="https://production-cmp.isgprivacy.cbsi.com/dist/optanon-v1.1.0.js"
                async
            ></script>
            <script type="text/javascript">
                {`!function(n){var o=window,a="cbsoptanon",c="cmd",d="config";o[a]=o[a]?o[a]:{},o[a][c]=o[a][c]?o[a][c]:[],o[a][d]=o[a][d]?o[a][d]:[],["onIframesReady","onFormsReady","onScriptsReady","onAdsReady"].forEach(function(n){o[a][n]=o[a][n]||function(){var d=arguments;o[a][c].push(function(){o[a][n].apply(o[a],d)})}})}();`}
            </script>
            {NewRelic()}

            {typeof window !== 'undefined' && (
                <link rel="canonical" key={window.location.href} href={window.location.href} />
            )}
            {description && <meta name="description" content={description.text} />}
            {keywords && <meta name="keywords" content={keywords.text} />}
            {image && <meta name="image" content={image.url} />}

            {ParselyLdJson(title, keywords, image, type, publishDate, articleSection, urlPath)}
        </Helmet>
    );
};

export default SEO;

SEO.propTypes = {
    title: PropTypes.object,
    description: PropTypes.object,
};

SEO.defaultProps = {
    title: null,
    description: null,
};
