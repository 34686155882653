import React from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import DynamicImage from '../DynamicImage/DynamicImage';
import ScrollRevealAnimation from '../ScrollRevealAnimation/ScrollRevealAnimation';
import './NavBar.scss';

const NavBar = ({ active, extraClass = '', logoNavBar = false, blocked }) => {
  return (
    <div className={`navbar ${extraClass}`}>
      <div className="container">
        {logoNavBar && (
          <div className="logo-container">
            <DynamicImage
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'left',
              }}
              fileName="garfield-logo-tm-white.png"
              className="logo"
              alt="Garfield Logo"
            />
          </div>
        )}
        <div className="navbar-section">
          <Link to="/">
            <ScrollRevealAnimation animation="fadeIn" offset="100px">
              {active === 'home' ? (
                <div className="nav-item-about active"></div>
              ) : (
                <div className="nav-item-about"></div>
              )}
            </ScrollRevealAnimation>
          </Link>
        </div>
        {!blocked && (
          <>
            <div className="navbar-section">
              <Link to="/videos">
                <ScrollRevealAnimation animation="fadeIn" offset="100px">
                  {active === 'videos' ? (
                    <div className="nav-item-videos active"></div>
                  ) : (
                    <div className="nav-item-videos"></div>
                  )}
                </ScrollRevealAnimation>
              </Link>
            </div>
          </>
        )}
        <div className="navbar-section">
          <Link to="/comics">
            <ScrollRevealAnimation animation="fadeIn" offset="100px">
              {active === 'comics' ? (
                <div className="nav-item-comics active"></div>
              ) : (
                <div className="nav-item-comics"></div>
              )}
            </ScrollRevealAnimation>
          </Link>
        </div>
        <div className="navbar-section">
          <Link to="/events">
            <ScrollRevealAnimation animation="fadeIn" offset="100px">
              {active === 'events' ? (
                <div className="nav-item-events active"></div>
              ) : (
                <div className="nav-item-events"></div>
              )}
            </ScrollRevealAnimation>
          </Link>
        </div>
      </div>
    </div>
  );
};

NavBar.propTypes = {};

const mapStateToProps = ({ blocked }) => {
  return { blocked };
};

export default connect(mapStateToProps, null)(NavBar);
