import React from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

const ScrollRevealAnimation = ({ animation, duration, delay, children, offset, animateOnce, className, id }) => {
    const [ref, inView] = useInView({
        rootMargin: `${offset} 0px`, // negative percentage/px y-value through element before firing
        triggerOnce: animateOnce,
    });
    return (
        <>
            {animation && (
                <div
                    className={`${className ? className : ''}  ${inView ? 'animated' : ''} ${inView ? animation : ''}`}
                    id={id}
                    style={{
                        animationDuration: duration + `s`,
                        animationDelay: inView ? delay + `s` : 0,
                        animationFillMode: `both`,
                        opacity: inView ? 1 : 0,
                    }}
                    ref={ref}
                >
                    {children}
                </div>
            )}
        </>
    );
};

ScrollRevealAnimation.propTypes = {
    animation: PropTypes.string.isRequired,
    animateOnce: PropTypes.bool,
    delay: PropTypes.number,
    duration: PropTypes.number,
    offset: PropTypes.string,
    className: PropTypes.string
};

ScrollRevealAnimation.defaultProps = {
    animation: '',
    animateOnce: true,
    delay: 0,
    duration: 1,
    offset: '-20%',
    children: null
};

export default ScrollRevealAnimation;
