import * as React from 'react';
import NickHeader from './NickHeader/NickHeader';
import Footer from './Footer/Footer';

export const Layout = ({ children, menu, className }) => {
    return (
        <div className={`${className}`}>
            <NickHeader />
            <main>{children}</main>
            <Footer />
        </div>
    );
};
