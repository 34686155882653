import React from 'react';

function ParselyLdJson(title = {}, keywords = '', image = '', type = '', publishDate = '', articleSection = '', urlPath='') {
    let structuredData = {
        '@context': 'http://schema.org',
        '@type': type,
        headline: title ? title.text : 'Garfield',
        url: process.env.GATSBY_PROJECT_DOMAIN + urlPath,
    };

    if (image) {
        structuredData['thumbnailUrl'] = image.url;
    }
    if (publishDate) {
        structuredData['datePublished'] = publishDate;
    }
    if (keywords) {
        structuredData['keywords'] = keywords.text.split(',');
    }
    if (articleSection) {
        structuredData['articleSection'] = articleSection;
        structuredData['creator'] = 'Garfield';
    }

    return <script type="application/ld+json">{JSON.stringify(structuredData)}</script>;
}

export default ParselyLdJson;
