export const countriesBlocked = {
    CN: 'China',
    HK: 'Hong Kong',
    MO: 'Macao',
    TW: 'Taiwan',
    MN: 'Mongolia',
    FR: 'France',
    MC: 'Monaco',
    AD: 'Andorra',
    LU: 'Luxembourg',
    BE: 'Belgium',
    CH: 'Switzerland',
    PF: 'French Polynesia',
    GF: 'French Guiana',
    GP: 'Guadeloupe',
    EG: 'Egypt',
    MQ: 'Martinique',
    PM: 'Saint Pierre and Miquelon',
    WF: 'Wallis and Futuna',
    RE: 'Reunion',
    YT: 'Mayotte',
    MF: 'Saint Martin',
    DZ: 'Algeria',
    AO: 'Angola',
    CP: 'Clipperton Island',
    BJ: 'Benin',
    BW: 'Botswana',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CM: 'Cameroon',
    CV: 'Cape Verde',
    CF: 'Central African Republic',
    TD: 'Chad',
    KM: 'Comoros',
    CG: 'Republic of the Congo',
    CD: 'Democratic Republic of the Congo',
    DJ: 'Djibouti',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    ET: 'Ethiopia',
    GA: 'Gabon',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    CI: 'Ivory Coast',
    GM: 'Gambia',
    GH: 'Ghana',
    BL: 'Saint Barthelemy',
    BH: 'Bahrain',
    BT: 'Bhutan',
    OM: 'Oman',
    PS: 'Palestinian Territory',
    IQ: 'Iraq',
    SO: 'Somalia',
    ZW: 'Zimbabwe',
    MG: 'Madagascar',
    MA: 'Morocco',
    ML: 'Mali',
    MH: 'Marshall Islands',
    QA: 'Qatar',
    MZ: 'Mozambique',
    NC: 'New Caledonia',
    KE: 'Kenya',
    LR: 'Liberia',
    MW: 'Malawi',
    MR: 'Mauritania',
    MU: 'Mauritius Island',
    NA: 'Namibia',
    NE: 'Niger',
    NG: 'Nigeria',
    RW: 'Rwanda',
    ST: 'Sao Tome and Principe',
    SN: 'Senegal',
    SL: 'Sierra Leone',
    SZ: 'Swaziland',
    SC: 'Seychelles',
    TZ: 'Tanzania',
    TG: 'Togo',
    TN: 'Tunisia',
    UG: 'Uganda',
    ZM: 'Zambia',
    CY: 'Cyprus',
    IR: 'Iran',
    IL: 'Israel',
    JO: 'Jordan',
    KW: 'Kuwait',
    LB: 'Lebanon',
    LY: 'Libya',
    SA: 'Saudi Arabia',
    SD: 'Sudan',
    SY: 'Syria',
    TR: 'Turkey',
    AE: 'United Arab Emirates',
    YE: 'Yemen',
    TF: 'French Southern Territories',
    YA: 'Adelie Land'
};
