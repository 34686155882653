import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer>
      <ul className="footer-links">
        <li>
          <a href="https://privacy.paramount.com/childrens-short" target="_blank" rel="nooopener noreferrer">
            Privacy Policy
          </a>
        </li>

        <li>
          <a href="https://www.nick.com/legal/ussoge/terms-of-use" target="_blank" rel="nooopener noreferrer">
            Terms of Use
          </a>
        </li>
      </ul>
      <p className="legal-text">
        © {new Date().getFullYear()} VIACOM INTERNATIONAL INC. ALL RIGHTS RESERVED. <br />
        <br />
        NICKELODEON AND ALL RELATED TITLES, LOGOS AND CHARACTERS ARE TRADEMARKS OF VIACOM INTERNATIONAL INC.
        <br />
        <br />© {new Date().getFullYear()} by Paws, Inc. All Rights Reserved. “GARFIELD” and the GARFIELD characters are
        trademarks of Paws, Inc.
      </p>
    </footer>
  );
};

export default Footer;
