import React, { useState } from 'react';
import './NickHeader.scss';

const NickHeader = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  return (
    <div className="nick-header">
      <header className="header">
        <div className="nick-logo">
          <a href="http://nick.com" title="Nick" target="_blank" rel="noopener noreferrer">
            <img src={require('../../images/nick-logo.png').default} alt="Nick Logo" />
          </a>
        </div>
        <div className="nick-pcy-terms">
          <div>
            <a
              href="https://privacy.paramount.com/childrens-short"
              title="Privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
          </div>
        </div>
        <button
          onClick={() => toggleMenu(!activeMenu)}
          className={`burger ${activeMenu ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </header>

      <div className={`menu ${activeMenu ? 'opened' : ''}`}>
        <ul>
          <li>
            <a
              href="https://privacy.paramount.com/childrens-short"
              target="_blank"
              color="#ffffff"
              rel="noreferrer noopenner"
            >
              PRIVACY POLICY
            </a>
          </li>

          <li>
            <a
              href="https://www.nick.com/legal/ussoge/terms-of-use"
              target="_blank"
              rel="noreferrer noopenner"
              color="#ffffff"
            >
              TERMS OF USE
            </a>
          </li>
        </ul>
        <div className="footer-menu">
          <p>
            © {new Date().getFullYear()} VIACOM INTERNATIONAL INC. <br />
            ALL RIGHTS RESERVED.{' '}
          </p>
          <p>NICKELODEON AND ALL RELATED TITLES, LOGOS AND CHARACTERS ARE TRADEMARKS OF VIACOM INTERNATIONAL INC.</p>
          <p>
            © {new Date().getFullYear()} by Paws, Inc. All Rights Reserved. “GARFIELD” and the GARFIELD characters are
            trademarks of Paws, Inc.
          </p>
        </div>
      </div>
    </div>
  );
};
export default NickHeader;
