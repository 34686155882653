import React, { useState, useEffect } from 'react';
import cookies from 'react-cookies';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { countriesBlocked } from '../../utils/countriesBlocked';
import './Loading.scss';

const Loading = ({ blocked, setBlocked }) => {
    const [showLoading, setShowLoading] = useState(true);
    const alreadyLoaded = cookies.load('garfield-rst') ? cookies.load('garfield-rst') : null;
    const apiURL =
        process.env.GATSBY_API_AUTH && process.env.GATSBY_API_AUTH !== ''
            ? `https://ipapi.co/json/?key=${process.env.GATSBY_API_AUTH}`
            : 'https://ipapi.co/json/';

    useEffect(() => {
        const html = document.querySelector('html');
        html.style.backgroundColor = '';
        const hideLoading = (blocked, timeout = false) => {
            if (!timeout) {
                setBlocked(blocked);
                setShowLoading(false);
            } else {
                setTimeout(() => {
                    setBlocked(blocked);
                    setShowLoading(false);
                }, 1000);
            }
        };
        //get ip of the client
        /* Not fetch the API if the value is already stored in the cookies */
        if (alreadyLoaded !== null) {
            hideLoading(alreadyLoaded);
        } else {
            fetch(`${apiURL}`)
                .then(function (response) {
                    response.json().then((jsonData) => {
                        console.log('current IP:', jsonData, countriesBlocked[jsonData.country_code]);
                        if (countriesBlocked[jsonData.country_code] && countriesBlocked[jsonData.country_code] !== '') {
                            navigate('/');
                            cookies.save('garfield-rst', true, { path: '/', maxAge: 30 * 24 * 60 * 60 });
                            hideLoading('true', true);
                        } else if (
                            jsonData.country_code === 'CA' &&
                            (jsonData.region_code === 'QC' || jsonData.region_code === 'NB')
                        ) {
                            navigate('/');
                            cookies.save('garfield-rst', true, { path: '/', maxAge: 30 * 24 * 60 * 60 });
                            hideLoading('true', true);
                        } else {
                            cookies.save('garfield-rst', false, { path: '/', maxAge: 30 * 24 * 60 * 60 });
                            /* Not blocked */
                            hideLoading('false', true);
                        }
                    });
                })
                .catch(function (error) {
                    navigate('/');
                    cookies.save('garfield-rst', true, { path: '/', maxAge: 30 * 24 * 60 * 60 });
                    hideLoading('true', true);
                    console.log(error);
                });
        }
    }, [alreadyLoaded, setBlocked]);

    return showLoading ? (
        <div className="loading">
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    ) : null;
};

const mapStateToProps = ({ blocked }) => {
    return { blocked };
};

const mapDispatchToProps = (dispatch) => {
    return { setBlocked: (blocked) => dispatch({ type: `CHECK_STATUS`, blocked: blocked === 'true' }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
