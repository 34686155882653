import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const DynamicImage = ({ fileName, animated, className, imgStyle, alt = '', ...props }) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    images: allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }) {
                        edges {
                            node {
                                relativePath
                                name
                                childImageSharp {
                                    gatsbyImageData(width: 1800)
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => {
                const image = data.images.edges.find((n) => {
                    return n.node.relativePath === fileName;
                });

                if (!image) {
                    return null;
                }
                const img = getImage(image.node);
                return <GatsbyImage image={img} alt={alt} className={className} imgStyle={imgStyle} />;
            }}
        />
    );
};

DynamicImage.propTypes = {
    fileName: PropTypes.string,
    animated: PropTypes.bool,
    className: PropTypes.string,
    animation: PropTypes.string,
    threshold: PropTypes.number,
    delay: PropTypes.number,
    duration: PropTypes.number,
    style: PropTypes.object,
};

export default DynamicImage;
